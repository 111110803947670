body.dark {
  .ant-layout-content {
    background-color: #002855 !important;
  }

  .ant-modal-content {
    background-color: #002855 !important;
  }

  .ant-modal-title {
    background-color: #002855 !important;
    color: #9ea3b5 !important;
  }

  .ant-layout-sider-children {
    ul {
      border-right: 2px solid #002855 !important;
    }
  }

  .ant-modal-close-x {
    color: #9ea3b5 !important; /* Set your desired color here */
  }
}
