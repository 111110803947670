.main_contents {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 0 15px 15px 15px;
}
.main_cont {
  width: 85%;
  height: 84vh;
  padding-right: 10px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
    margin: 0 15px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fcfcfc;
    margin: 0 15px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #0961e5;
  }
}
