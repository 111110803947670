.header {
  border-radius: 12px;
  box-shadow: 0px 1px 1px 0px #00000026;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  background-color: #ffffff;
  margin: 15px;
  
}
.header_right {
  display: flex;
  align-items: center;
  gap: 14px;
}
.header_status{
  display: flex;
}
.header_link {
  &:nth-child(1) {
    display: flex;
    align-items: center;
    gap: 4px;
    span {
      padding: 1px 5px 1px 5px;
      border-radius: 4px;
      background-color: #e94c13;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.52px;
      letter-spacing: 0.02em;
      text-align: center;
      color: #ffffff;
    }
  }
  &:nth-child(2){
    div{
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border: 1px solid #b2b2b2;
        padding: 10%;
        svg{
          width: 100%;
          height: 100%;
        }
    }
  }
}
