.sidebar {
  width: 15%;
  height: 84vh;
  position: sticky;
  left: 0;
  border-radius: 12px !important;
  background: #ffffff;
  padding: 10px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.sidebar_links {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: scroll;
  height: 75%;
  &::-webkit-scrollbar {
    width: 0px !important;
  }
}
.sidebar_link.active {
  background-color: #f5f7f9;
  color: #1e1e1e !important;
  svg {
    fill: #1e1e1e;
    path {
      fill: #1e1e1e;
      stroke: #1e1e1e;
    }
  }
}
.sidebar_link {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #666666;
  padding: 12px;
  gap: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    fill: #666666;
    path {
      fill: #666666;
      stroke: #666666;
    }
  }
  &:hover {
    background-color: #00000000;
    color: #1e1e1e !important;
    svg {
      fill: #1e1e1e;
      path {
        fill: #1e1e1e;
        stroke: #1e1e1e;
      }
    }
  }
}
