@charset "UTF-8";
@import "fonts";
@import "normalize";
@import "variables";
@import "general";
@import "other";
@import "container";
@import "mixins";
@import "dark";
@import "blocks/blocks";

.page_navbar {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 20;
  align-items: center;
  background-color: #ffffff;
  justify-content: space-between;
  border-radius: 12px;
  margin-bottom: 15px;
  padding: 15px;
  .own_link {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    color: #1e1e1e;
  }
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .search {
    padding: 8px 14px 8px 14px !important;
  }
  .cancel_link {
    background: #f5f7f9;
    color: #1e1e1e;
    display: flex;
    align-items: center;
    padding: 8px 14px 8px 14px;
    gap: 6px;
    border-radius: 8px;
    &:hover {
      color: #1e1e1e !important;
    }
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: #ffffff;
      }
    }
  }
  .add_link {
    background: #0961e5;
    color: #ffffff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 8px 14px 8px 14px;
    gap: 6px;
    border-radius: 8px;
    white-space: nowrap;
    &:hover {
      background-color: #0961e5 !important;
      color: #ffffff !important;
    }
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: #ffffff;
      }
    }
  }
}

