.register-login {
  display: flex;
  justify-content: space-between;
  height: 100vh;
  .waiting-text{
    font-weight: 600;
    font-size: 32px;
  }
  .rl_left {
    background-color: white;
    width: 40%;
    padding: 2% 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    place-content: space-between;
    height: 100%;
    p.label {
      font-size: 14px;
      padding-top: 10px;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .ant-upload{
      div{
        margin-right: -20px;
      }
    }
    .ant-upload-list-item-action {
      padding: 0 !important;
      margin: 0;
      &:hover {
        background: none !important;
      }
    }
    .ant-upload-list-text{
      padding: 0 !important;
    }
    .ant-upload-list-item-done{
      margin: 0 !important;
    }
    .ant-upload-icon {
      margin-top: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-upload-list {
      margin: 0;
      padding: 0 !important;
      width: fit-content;
      padding: 6px 16px 6px 16px !important;

      &:hover {
      background-color: none !important;
      }
      .ant-upload-list-item-done{
        background-color: none !important;
      }
      .ant-upload-list-item-container {
        margin: 0 !important;
        padding: 0 !important;
        height: fit-content !important;
        &:hover {
          background-color: none !important;
          }
      }
      .ant-upload-list-item-error {
        margin: 0 !important;
        padding: 0 !important;
        display: flex;
        align-items: center;
        &:hover {
          background-color: none !important;
          }
      }
    }
    .ant-upload-wrapper {
      width: fit-content !important;
      display: flex;
      margin-top: 25px;
      background-color: #f3f3f3 !important;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      .ant-upload {
        margin: 0 !important;
        width: fit-content !important;
        button {
          padding: 6px 16px 6px 16px !important;
          width: 100% !important;
          gap: 10px;
          display: flex !important;
          align-items: center !important;
        }
        div {
          margin-top: 0 !important;
        }
      }
    }

    input {
      background-color: #f3f3f3 !important;
      border: none;
      padding: 10px;
      &::placeholder {
        color: #9f9f9f !important;
      }
      &:focus {
        border-color: none !important;
        outline: 0 !important;
        -webkit-box-shadow: 0 0 5px 1px var(--primary-color) !important;
        box-shadow: 0 0 5px 1px var(--primary-color) !important;
      }
    }
    h1 {
      font-size: 25px;
      font-weight: 600;
    }
    div {
      margin-top: 7px;
    }
    .ant-checkbox-wrapper:focus {
      border-color: var(--primary-color) !important;
      outline: var(--primary-color) !important;
    }
    .ant-checkbox-input:hover,
    .ant-checkbox-input:focus {
      border-color: var(--primary-color) !important;
      outline: var(--primary-color) !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
    }

    .privacy-check {
      display: flex;
      align-items: center;
      margin: 15px 0;
      p {
        font-size: 16px;
        color: #9f9f9f;
        line-height: 24px;
        a {
          color: black;
          text-decoration: underline;
        }
      }
    }
    button {
      padding: 18px 36px 18px 36px;
      border-radius: 65px;
      -webkit-border-radius: 65px;
      -moz-border-radius: 65px;
      -ms-border-radius: 65px;
      -o-border-radius: 65px;
    }
    .login-text {
      display: flex;
      gap: 10px;
      font-size: 15px;
      font-weight: 400;
      align-items: center;
      justify-content: center;
      a {
        text-decoration: underline;
        color: blue;
      }
    }
  }
  .rl_right {
    width: 60%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
