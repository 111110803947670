.container {
  max-width: 1600px;
  background-color: unset !important;
}
.container-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #efefef;
}
